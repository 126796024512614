
import { ToastSimpleComponent as toast } from '../../app/core/components/toast-simple/toast-simple.component';
import { SplashSimpleComponent as splash } from '../../app/core/modals/splash-simple/splash-simple.component';

export const appDependencies = [toast, splash];


export const PlatformeLangs = {
  langs: [
    {
      code: 'fr-ch',
      name: 'Fr',
      fullName: 'Français'
    }
  ],
  default: 'fr'
};

export const PlatformeColors = {
  $primary: '#C11C03',
  $secondary: '#282828',
  $tertiary: '#989898',
  $dark:'#343A40'
};

export const appConfig = {
  title: 'Global-academy',
  defaultCatId: 2,
  breadcrumbs:true,
  displayNewsInSliders: false,
  specPassport:[
    {
      id:1,
      name_lang:'fr'
    },
    {
      id:1,
      name_lang:'de'
    }
  ]

};

export const NavigationBar = {
  elements: [
    {
      name: 'Mes formations',
      className: 'icon-formations',
      link: 'catalogue'
    },
    {
      name: 'Documents',
      className: 'icon-documents1',
      link: 'document'
    },
    {
      name: 'Rapports',
      className: 'icon-rapports',
      link: 'rapport'
    },
    {
      name: 'Evaluations',
      className: 'icon-evaluation1',
      link: 'gradebook'
    },
    /* {
      name: 'Fichiers',
      className: 'icon-attachment',
      link: 'fichier'
    }, */
    {
      name: 'Passeport',
      className: 'icon-passeport',
      link: 'passeport'
    },
    /* {
      name: 'Calendriers',
      className: 'icon-calendar',
      link: 'calendriers'
    }, */
    {
      name: 'Aide',
      className: 'icon-aide1',
      link: 'aide'
    },
  ]
};


export const NavigationTabAdmin = {
  elements:[
    {
      name: 'Inscription',
      className: 'icon-user-add mr-2',
      link: 'user-manage',
      permission:"CAN-MANAGE-REGISTER"
    },
    {
      name: 'Groupes',
      className: 'icon-user-group mr-2',
      link: 'groups',
      permission:"CAN-MANAGE-GROUPE"
    },
    {
      name: 'Utilisateurs',
      className: 'icon-user-list mr-2',
      link: 'liste',
      permission:"CAN-MANAGE-USERS"
    },
    {
      name: 'Evaluations',
      className: 'icon-evaluation mr-2',
      link: 'evaluation',
      permission:"CAN-MANAGE-EVALUATIONS"
    }
    ,
    {
      name: 'Centre de notifications',
      className: 'icon-notification mr-2',
      link: 'centre-notification',
      permission:"CAN-MANAGE-NOTIFICATIONS"
    } ,
    // {
    //   name: 'Tags',
    //   className: 'icon-tompon mr-2',
    //   link: 'tags'
    // }
    // ,
    // {
    //   name: 'Template',
    //   className: 'icon-paint mr-2',
    //   link: 'templates'
    // }
  ],subElements:[
    {
      name: 'Inscription',
      className: 'icon-user-add mr-2',
      link: '/admin/user-manage',
      permission:"CAN-MANAGE-REGISTER"
    },
    {
      name: 'Groupes',
      className: 'icon-user-group mr-2',
      link: '/admin/groups',
      permission:"CAN-MANAGE-GROUPE"
    },
    {
      name: 'Utilisateurs',
      className: 'icon-user-list mr-2',
      link: '/admin/liste',
      permission:"CAN-MANAGE-USERS"
    },
    {
      name: 'Evaluations',
      className: 'icon-evaluation mr-2',
      link: '/admin/evaluation',
      permission:"CAN-MANAGE-EVALUATIONS"
    }
    ,
    {
      name: 'Centre de notifications',
      className: 'icon-notification mr-2',
      link: '/admin/centre-notification',
      permission:"CAN-MANAGE-NOTIFICATIONS"
    } ,
    // {
    //   name: 'Tags',
    //   link: '/admin/tags'
    // }
    // ,
    // {
    //   name: 'Template',
    //   link: '/admin/templates'
    // }
  ]
}



export const enableButtonsList = {
  // "toArchive": false,
  "toEnroll": true,
  // "toAffect": false,
  // "toEdit": false,
  "toView": false,
  "portfolio":true

}


export const formAdminEditionUser = {
  // "entite": false,
  // "civilite": false,
  // "departement": false,
  // "activation": false
}


export const configAdminListUsers = {
  //"toEnroll": false,

}

export const configProfil = {
  // "signature": false,
  // "edit_password":false


}

export const spaceBottomNavBar={
}

export const adminGroupListConfig={
  "image":true
}
export const sequencePermissionAccess={
  "status":true
}

export const sequenceDescriptionModal={
  "status":false
}

export const cancelReservation={
  "status":true
}

export const ga_dev = {
  "key": "G-XXXXX"
}

export const ga_prod = {
  "key": "G-6PBXXXXX"
}
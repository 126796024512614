import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeGuard } from './core/guards/home.guard';

const routes: Routes = [
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'not-connected',
    loadChildren: () => import('./not-connected/not-connected.module').then(m => m.NotConnectedModule)
  },
  {
    path: 'print',
    loadChildren: () => import('./printing/printing.module').then(m => m.PrintingModule),
    // canLoad: [HomeGuard]
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canLoad: [HomeGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

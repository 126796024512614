  <!-- Position it -->
  <div class="toast-card">
    <!-- Then put toasts within -->
    <div [id]="toast.id" *ngFor="let toast of toasts; let i=index" class="toast" role="alert" aria-live="assertive"
       aria-atomic="true" [ngClass]="{
        'toast-success': toast.type == 'SUCCESS',
       'toast-error': toast.type == 'ERROR',
       'toast-warning': toast.type == 'WARNING',
       'toast-info': toast.type == 'INFO',
       'toast-notif': toast.type == 'NOTIF'
      }">
      <div class="toast-header">
        <!-- <img src="..." class="rounded mr-2" alt="..."> -->
        <ng-container [ngSwitch]="toast.type">
          <ng-container *ngSwitchCase="'INFO'">
            <i class="rounded mr-2 icofont-info-circle"></i>
            <strong class="mr-auto" translate>Information</strong>
          </ng-container>
          <ng-container *ngSwitchCase="'SUCCESS'">
            <i class="rounded mr-2 icofont-verification-check"></i>
            <strong class="mr-auto" translate>Succès</strong>
          </ng-container>
          <ng-container *ngSwitchCase="'WARNING'">
            <i class="rounded mr-2 icofont-warning"></i>
            <strong class="mr-auto" translate>Avertissement</strong>
          </ng-container>
          <ng-container *ngSwitchCase="'NOTIF'">
            <i class="rounded mr-2 icofont-alarm"></i>
            <strong class="mr-auto" translate>Notification</strong>
          </ng-container>
          <ng-container *ngSwitchCase="'ERROR'">
            <i class="rounded mr-2 icofont-error"></i>
            <strong class="mr-auto" translate>Erreur</strong>
          </ng-container>
        </ng-container>

        <!-- <small class="text-muted">just now</small> -->
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="toast-body">
      <div><b><span>{{ toast?.title | translate}}</span></b></div>

        <span>{{ toast.message | translate}}</span>
      </div>
    </div>

  </div>

import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserHttpService } from '../http/user-http.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanLoad {

  constructor(private userHttp: UserHttpService, private router: Router, private user: UserService,private trans:TranslateService) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    return this.userHttp.getUser().pipe(
      map((res: any) => {
        if (res) {
          this.user.setJWT(res);
          //this.trans.use()       //use lang user
        } else {
          window.location.href = '/logout';
        }
        return true;
      }),
      catchError((err: any, caught: any) => {
        window.location.href = '/logout';
        return of(false);
      })
    );

    return true;
  }
}

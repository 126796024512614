import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PlatformeLangs } from '../client/configs/app.config';
import { appConfig } from '../client/configs/app.config';
import { UserHttpService } from './core/http/user-http.service';
import { GoogleTagManagerService } from './core/services/google-tag-manager.service';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  userLang;
  userId;
  appConfig = null;

  constructor(private translate: TranslateService, private userService: UserHttpService, private usr: UserService, private router: Router, private gtmService: GoogleTagManagerService) {
    //  this.userService.getUser().subscribe( user =>{
    //   this.userLang = user.data.lang.value.split('-')[0];
    //   console.log("userInfoLang2==>",this.userLang);
    //   if(this.userLang){
    //       translate.use(this.userLang);
    //       translate.setDefaultLang(this.userLang);
    //   }else{
    //     translate.setDefaultLang(PlatformeLangs.default);
    //   }
    // });
    // translate.addLangs(['en', 'it']);
    // translate.use(PlatformeLangs.default);
    // document.title = this.translate.instant(appConfig.title);
    // console.log(document.title);
    // translate.setDefaultLang(PlatformeLangs.default);
    const favIcon: HTMLLinkElement = document.querySelector('#favicon');
    favIcon.href = './client/assets/favicon.ico';
    // router.events.subscribe((val)=>{
    //   this.closeNav();
    // })
  }

  ngOnInit() {
    this.appConfig = appConfig;
    if (localStorage.getItem('JWT')) {


      // console.log("Connected");

      this.userService.getUser().subscribe(res => {
        this.userLang = res.data.profile?.lang.split('-')[0];
        this.userId = res.data.id;
        //console.log(this.userId);

        this.translate.setDefaultLang(this.userLang);

      })


    }

    // push GTM data layer for every visited page
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

    // this.translate.setDefaultLang(PlatformeLangs.default);
    // todo: set language to archive
  }


  hasPermission(slug: string): boolean {
    return true;
  }

  openNav() {
    // (document.getElementById("myNav") as HTMLElement).style.width = "100%";
    // (document.getElementsByClassName("closebtn")[0] as HTMLElement).style.display = "inline";
    window.location.href = "/landing"
    //this.router.navigateByUrl('/home2');
  }

  closeNav() {
    (document.getElementById("myNav") as HTMLElement).style.width = "0%";
    (document.getElementsByClassName("closebtn")[0] as HTMLElement).style.display = "none";
  }
}

import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({

  providedIn: 'root'

})

export class GoogleTagManagerService {

  private isLoaded = false;

  private config: any = null;

  private browserGlobals = {

    windowRef(): any {

      return window;

    },

    documentRef(): any {

      return document;

    },

  };

  constructor() {

    this.config = environment.gtmConfig;

  }

  public getDataLayer(): any[] {

    const window = this.browserGlobals.windowRef();

    window.dataLayer = window.dataLayer || [];

    return window.dataLayer;

  }

  private pushOnDataLayer(obj: object): void {

    const dataLayer = this.getDataLayer();

    dataLayer.push(obj);

  }

  public addGtmToDom(): Promise<boolean> {

    return new Promise((resolve, reject) => {

      if (this.isLoaded) {

        return resolve(this.isLoaded);

      }

      const doc = this.browserGlobals.documentRef();

      this.pushOnDataLayer({

        'gtm.start': new Date().getTime(),

        event: 'gtm.js',

      });

      const gtmScript = doc.createElement('script');

      gtmScript.id = 'GTMscript';

      gtmScript.async = true;

      gtmScript.src = this.applyGtmQueryParams(

        this.config.gtmResourcePath ? this.config.gtmResourcePath : 'https://www.googletagmanager.com/gtm.js'

      );

      gtmScript.addEventListener('load', () => {

        return resolve(this.isLoaded = true);

      });

      gtmScript.addEventListener('error', () => {

        return reject(false);

      });

      doc.head.insertBefore(gtmScript, doc.head.firstChild);

    });

  }

  public pushTag(item: object): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      if (!this.isLoaded) {

        this.addGtmToDom().then(() => {

          this.pushOnDataLayer(item);

          return resolve();

        }).catch((err) => reject(err) );

      } else {

        this.pushOnDataLayer(item);

        return resolve();

      }

    });

  }

  private applyGtmQueryParams(url: string): string {

    if (url.indexOf('?') === -1) {

      url += '?';

    }

    

    const params = Object.keys(this.config.queryParams)

      .filter((k) => this.config.queryParams[k])

      .map((k) => `${k}=${this.config.queryParams[k]}`)

      .join('&');

    return url + `id=${this.config.gtmKey}` + (params ? "&" + params : "");

  }

}
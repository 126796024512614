import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import { Subject } from 'rxjs';
import { notifConfig } from 'src/client/configs/notif.config';
import { environment } from 'src/environments/environment';
import { NotificationsHttpService } from '../http/notifications-http.service';
import { ToastService } from './toast.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SplashService {

  private subject = new Subject<object>();

  $sub = this.subject.asObservable();

  // private splash = null;

  constructor(private toast: ToastService, private user: UserService, private notifHttp: NotificationsHttpService) {
    if (environment.lara_websockets && notifConfig.hasOwnProperty("enable_notification") && notifConfig.enable_notification===true){
       this.loadEchoLara();
    }
  }

  /* get(): any[] {
    return this.splash;
  } */

  loadEchoLara(): void {
    const echoLara = new Echo({
      broadcaster: 'pusher',
      key: environment.PUSHER_APP_KEY,
      wsHost: environment.wsHost,
      wsPort: environment.wsPort,
      wssPort: environment.wssPort,
      forceTLS: environment.forceTLS,
      disableStats: true,
      enabledTransports: ['ws', 'wss']
    });
    this.user.$sub.subscribe(() => {
      echoLara.channel('channel-splash-' + this.user.getUser().id).listen('SendNotif', (e: any) => {
        const splash = e;
        this.subject.next(splash);
      });
    });
  }

  rm(splashIds: any): void {
    this.notifHttp.updateNotif({ notif_ids: splashIds, status: 'CLOSED'}).subscribe((res) => {});
    // this.splash = null;
    // this.subject.next(this.splash);
  }

  add(splashObj: object): void {
    // this.splash = this.splash;
    this.subject.next(splashObj);
  }
}

import { Component, OnInit } from '@angular/core';
import { SplashService } from '../../services/splash.service';

@Component({
  selector: 'app-splash-simple, app-splash',
  templateUrl: './splash-simple.component.html',
  styleUrls: ['./splash-simple.component.scss']
})
export class SplashSimpleComponent implements OnInit {

  splashs = [];
  selectedSlashIndex = 0;

  constructor(private splashService: SplashService) { }

  ngOnInit(): void {
    this.splashService.$sub.subscribe((splash) => {
      this.splashs.unshift(splash);
      this.selectedSlashIndex = 0;
      $('#splashModal').modal('show');
    });
  }

  rm(): void {
    this.splashService.rm(this.splashs.map(s => s.id));
    this.splashs = [];
  }

  next() {
    if (this.selectedSlashIndex < this.splashs.length - 1) {
      this.selectedSlashIndex += 1;
    }
  }

  back() {
    if (this.selectedSlashIndex > 0) {
      this.selectedSlashIndex -= 1;
    }
  }

}

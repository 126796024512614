import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsHttpService {

  constructor(private http: HttpClient) { }

  send(payload: any): Observable<any> {
    return this.http.post('/api/notification/sendnotifications', payload);
  }
  getNotificationsHistories(): Observable<any> {
    return this.http.get('/api/notification/notificationshistorique');
  }

  getAllNotifs(userId: string): Observable<any> {
    return this.http.get('/api/notification/getnotification/' + userId);
  }

  updateNotif(payload: { notif_ids: string[], status: string }): Observable<any> {
    return this.http.post('/api/notification/updatenotification', payload);
  }
  getMailTemplate(){
    return this.http.get('/api/notification/getemailtemplatenotification').pipe(
      map((response:any)=> response?.data)
    );
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastService, ToastTypes } from '../services/toast.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(private toast: ToastService) { }

  /*
  code: 200
data: true
message: "Fichiers bien ajoutés"
status: "Success" */

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.get("skip"))
           return next.handle(request);
    return next.handle(request).pipe(
     /*  tap((evt: any) => {


      }), */
      map((res: any) => {
        if ('message' in res && (res.message === null || res.message === '')) {
          delete res.message;
        }
        if ('body' in res && 'message' in res.body && (res.body.message === null || res.body.message === '')) {
          delete res.message;
        }
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status >= 400) {

          if ('errors' in err.error) {
            for (const key in err.error.errors) {
              if (Object.prototype.hasOwnProperty.call(err.error.errors, key)) {
                const errRlm = err.error.errors[key];
                errRlm.forEach(errString => {
                  this.toast.show(errString, ToastTypes.error);
                });
              }
            }
          } else {
            this.toast.show(err.error.message, ToastTypes.error);
          }

        }
        return throwError(err);
      })
    );
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


import {ga_dev} from "../client/configs/gtag.conf";

export const environment = {
  production: true,
  server: '',
  app: '/app/',
  lara_websockets: true,
  PUSHER_APP_KEY: '29bdf75081020201d1d7',
  wsHost: window.location.hostname,
  wsPort: 6002,
  wssPort: 6002,
  disableStats: true,
  forceTLS: false,
  enabledTransports: ['ws', 'wss'],
  ga: ga_dev?.key,
  gtmConfig: ga_dev?.gtmConfig
};
//G-2X0XSLR5ZB
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const ga_dev = {
  key: "",
  gtmConfig: {
    gtmResourcePath: null,
    gtmKey: "",
    queryParams: {
      gtm_auth: null,
      gtm_preview: null,
    },
  }
}

export const ga_prod = {
  key: "",
  gtmConfig: {
    gtmResourcePath: null,
    gtmKey: "",
    queryParams: {
      gtm_auth: null,
      gtm_preview: null,
    },
  }
}

<div class="container">
    <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center mt-5">
            <div class="error-template">
                <h1>
                    Oops!</h1>
                <div class="error-details mb-3" translate>
                    notFoundMessage
                </div>
                <div class="error-actions" >
                    <a href="/" class="btn btn-primary btn-lg"><span class="icon-home"></span>
                       <span translate>notFoundRedirection</span></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="splashModal" data-backdrop="static" tabindex="-1" aria-labelledby="splashModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="splashModalLabel">{{ splashs[selectedSlashIndex]?.title }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="rm()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span [innerHTML]="splashs[selectedSlashIndex]?.description"></span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark mr-auto" data-dismiss="modal" (click)="rm()" translate>Fermer</button>
        <button type="button" class="btn btn-primary" [disabled]="selectedSlashIndex == 0" (click)="back()" translate>
          <i class="icon-chevron-left"></i>
        </button>
        <span>{{selectedSlashIndex + 1}}/{{ splashs.length }}</span>
        <button type="button" class="btn btn-primary" [disabled]="selectedSlashIndex == splashs.length - 1" (click)="next()" translate>
          <i class="icon-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>

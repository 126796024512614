import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private subject = new Subject<object>();

  $sub = this.subject.asObservable();

  constructor() { }

  show(toastMessage: string, toastType: ToastTypes,title:string=null): void {
      // if(title!==null)
      // var message=toastMessage+" \n "+title;
      // else
      // message=toastMessage;
    this.subject.next({
      event: 'SHOW',
      options: {
        id: '_' + Math.random().toString(36).substr(2, 4),
        message: toastMessage,
        title:title,
        type: toastType
      }
    });
  }

  showResMessage(res: any, toastType: ToastTypes): void {
    let msg = null;
    if ('message' in res && (res.message !== null || res.message !== '')) {
      msg = res.message;
    }
    if ('body' in res && 'message' in res.body && (res.body.message !== null || res.body.message !== '')) {
      msg = res.body.message;
    }
    if (msg) {
      this.subject.next({
        event: 'SHOW',
        options: {
          id: '_' + Math.random().toString(36).substr(2, 4),
          message: msg,
          type: toastType
        }
      });
    }
  }

  hideAll(): void {
    this.subject.next({
      event: 'HIDEALL',
      options: null
    });
  }

}

export enum ToastTypes {
  success = 'SUCCESS',
  error = 'ERROR',
  warning = 'WARNING',
  info = 'INFO',
  notif = 'NOTIF'
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private router: Router, private user: UserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let req = request;
    const token = localStorage.getItem('JWT');
    if (token) {
      req = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    return next.handle(req).pipe(
      tap((res) => { /* if (res instanceof HttpResponse) {} */ }
        , (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.user.logout();
              // this.router.navigateByUrl('/'); // navigate to login
            }
          }
        })
    );
  }
}

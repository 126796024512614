import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  constructor(private http: HttpClient) {
  }

  getUser(): Observable<any> {
    return this.http.get(environment.server + '/getuser');
  }

  getListUsers(): Observable<any> {
    return this.http.get('/api/gradebook/specs');
  }

  getListUsersByGroup(id: number): Observable<any> {
    return this.http.get('/api/admin/getusersbygroup/' + id);
  }

  getListUsersBySearch(search: string): Observable<any> {
    return this.http.get('/api/admin/getusersbysearch/' + search);
  }

  // upload avatar  profil
  uploadAvatarPic(formdata: any, options: any): Observable<any> {
    // return this.http.post("/api/upload/csv",formdata,options)
    return this.http.post<any>('/api/admin/addavatar', formdata, options);
  }

  // user profil add signature

  addSignature(data: any): Observable<any> {
    return this.http.post<any>('/api/admin/addsignature', data);
  }

  updateEmail(data: any): Observable<any> {
    return this.http.post<any>('/api/admin/changeemail', data);

  }

  acceptConditions(data: any): Observable<any> {
    return this.http.post<any>('/api/admin/changecondition', data);
  }

  updatePassword(data: any): Observable<any> {
    return this.http.post<any>('/api/password/newpassword', data);
  }

  getSingleUser(id: number): Observable<any> {
    return this.http.get(`/api/admin/getuserprofilebyid/${id}`);
  }


  searchUserWithGroup(data): Observable<any> {
    return this.http.post(`/api/admin/getuserprofilebyid`, data);
  }

  getDepartementByLang():Observable<any>{
    return this.http.get(`/api/admin/getdepartmentsbyuserlang`);
  }


}

import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast-simple, app-toast',
  templateUrl: './toast-simple.component.html',
  styleUrls: ['./toast-simple.component.scss']
})
export class ToastSimpleComponent implements OnInit {

  toasts: Array<object> = [];

  constructor(private toast: ToastService) {}

  ngOnInit(): void {

    this.toast.$sub.subscribe((e: any) => {
      switch (e.event) {
        case 'SHOW':
          this.showToast(e.options);
          break;
        case 'HIDEALL':
          $('.toast').toast('hide');
          break;
        default:
          break;
      }
    });
  }

  showToast(options: any): void {
    this.toasts.push(options);
    setTimeout(() => {
      $('#' + options.id).on('hidden.bs.toast', () => {
        $('#' + options.id).off(); // Remove all event handlers
        const removeIndex = this.toasts.map((item: any) => item.id).indexOf(options.id); // Get index of element
        this.toasts.splice(removeIndex, 1); // Remove element
      });
      $('#' + options.id).toast({ delay: 3000 }).toast('show');
    }, 0);
  }

}
